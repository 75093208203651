import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  cardWrapper: {
    padding: '2rem 0rem',
    [theme.breakpoints.down('md')]: {
      padding: '2rem 0rem',
    },
  },
  blogImgWrapper: {
    position: 'relative',
    '&:hover': {
      '& $blogImg': {
        opacity: '0.7',
      },
    },
  },
  blogImg: {
    width: '100%',
    height: '450px',
    objectFit: 'cover',
  },

  blogCategory: {
    position: 'absolute',
    top: '1rem',
    left: '1rem',
  },

  btnCategory: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '500',
    minWidth: '90px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.8',
      boxShadow: 'none',
    },
  },
  blogContent: {
    padding: '1rem 1rem',
  },

  blogTitleLink: {
    textDecoration: 'none',
    color: ' #263238',
  },

  blogTitle: {
    fontSize: '1rem',
    fontWeight: 600,
    color: theme.palette.black[100],
    marginBottom: '1rem',
    wordBreak: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minHeight: '3rem',
    '&:hover': {
      color: theme.palette.lightBlue.dark,
    },
  },
  blogTimeWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },

  blogTimeWrapperMobile: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '.5rem',
    marginBottom: '.6rem',
  },
  blogTime: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
    '& p': {
      fontSize: '.8rem',
      fontWeight: 400,
      color: theme.palette.black[100],
      margin: '0px',
    },
  },

  userWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
    ' & p': {
      fontSize: '.8rem',
      fontWeight: 400,
      color: theme.palette.black[100],
      margin: '0px',
    },
  },

  blogDescription: {
    fontSize: '.9rem',
    fontWeight: 500,
    color: theme.palette.black[100],
    marginBottom: '1rem',
    wordBreak: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minHeight: '4rem',
  },

  blogDescriptionWrapper: {
    // minHeight: '10rem',
  },
  blogReadMore: {
    // height: '20rem',
  },
  btnMore: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '500',
    textTransform: 'capitalize',
    // maxHeight: '5rem',

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.8',
      boxShadow: 'none',
    },
  },
}));
