import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'src/components';
import { routing } from 'src/utils';
import { useNavigate } from 'react-router-dom';
import BlogRelatedPosts from '../BlogRelatedPosts';
import * as service from 'src/service';
import moment from 'moment';
import { ActionButton } from 'src/components/Forms';
import PersonIcon from '@mui/icons-material/Person';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Box, Typography, Card, CardContent, Divider } from '@mui/material';
import { useStyles } from './BlogContentStyles';

interface IBlogContent {
  blog: Record<string, any>;
}
const BlogContent: FC<IBlogContent> = ({ blog }) => {
  const navigate = useNavigate();
  const [relatedPosts, setRelatedPosts] = useState<Record<string, any>[]>([]);
  const classes = useStyles();

  useEffect(() => {
    service.blog
      .getRelatedPosts(blog?.category)
      .then((res) => {
        setRelatedPosts(res.data.blogs);
      })
      .catch(console.error);
  }, [blog?.category]);

  return (
    <>
      <Card sx={{ boxShadow: 'none' }}>
        <CardContent>
          <Typography variant='h2' className={classes.detailsTitle}>
            {blog.title}
          </Typography>
          <Box className={classes.detailsTimeWrapper}>
            <Box className={classes.detailsTime}>
              <CalendarMonthIcon />
              <Typography variant='body2'>
                {moment.utc(blog.createdAt).startOf('minute').fromNow()}
              </Typography>
            </Box>
            <Link
              to={`${routing.blogUser}/${blog?.user?._id}`}
              className={classes.blogTitleLink}
            >
              <Box className={classes.userWrapper}>
                <PersonIcon />
                <Typography variant='body2'>{blog?.user?.name}</Typography>
              </Box>
            </Link>
          </Box>
          <Image
            src={blog?.thumbnail?.url}
            alt={blog.title}
            fallback={blog?.thumbnail?.url}
            className={classes.detailsImg}
          />

          <div
            className={classes.detailsText}
            dangerouslySetInnerHTML={{
              __html: blog.content,
            }}
          ></div>
          <Divider />
          {blog?.tags?.length > 0 &&
          blog.tags.some((tag: string) => tag.trim() !== '') ? (
            <Box className={classes.detailsTags}>
              <Typography variant='body2'>Tags</Typography>
              <Box className={classes.btnTagsWrapper}>
                {blog?.tags?.map((tag: string) => {
                  return (
                    <ActionButton
                      onClick={() => navigate(`/blog/tag/${tag}`)}
                      className={classes.btnTags}
                      key={tag}
                    >
                      {tag}
                    </ActionButton>
                  );
                })}
              </Box>
            </Box>
          ) : null}
        </CardContent>
      </Card>

      <BlogRelatedPosts relatedPosts={relatedPosts} />
    </>
  );
};

export default BlogContent;
