import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Image } from 'src/components';
import { ActionButton } from 'src/components/Forms';
import { routing } from 'src/utils';
import moment from 'moment';
import PersonIcon from '@mui/icons-material/Person';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import { useStyles } from './BlogRelatedPostsStyles';

interface IRelatedItems {
  relatedPosts: Record<string, any>;
}

const RelatedItems: FC<IRelatedItems> = ({ relatedPosts }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card>
        <CardContent sx={{ padding: 0 }}>
          <Image
            src={relatedPosts.thumbnail.url}
            alt={relatedPosts.title}
            fallback={relatedPosts.thumbnail.url}
            className={classes.cardImg}
          />
          <Box className={classes.cardContent}>
            <Box className={classes.featureWrapper}>
              <Box className={classes.timePosted}>
                <CalendarMonthIcon />
                <Typography variant='body2'>
                  {moment(relatedPosts.createdAt).format('DD MMM YYYY')}
                </Typography>
              </Box>
              <Box className={classes.category}>
                <LocalOfferIcon />
                <Link
                  to={`${routing.blogCategory}/${relatedPosts.category}`}
                  className={classes.blogTitleLink}
                >
                  <Typography variant='body2'>
                    {relatedPosts.category}
                  </Typography>
                </Link>
              </Box>
            </Box>

            <Box className={classes.descriptions}>
              <Link
                to={`${routing.blog}/${relatedPosts.slug}`}
                className={classes.blogTitleLink}
              >
                <Typography variant='body1'>
                  {relatedPosts.title.length > 50
                    ? relatedPosts.title.substring(0, 50) + '...'
                    : relatedPosts.title}
                </Typography>
              </Link>
              <Typography variant='body2'>
                {relatedPosts.description.length > 100
                  ? relatedPosts.description.substring(0, 100) + '...'
                  : relatedPosts.description}
              </Typography>
            </Box>

            <Box className={classes.btnWrapper}>
              <Box className={classes.userWrapper}>
                <PersonIcon />
                <Link
                  to={`${routing.blogUser}/${relatedPosts.user._id}`}
                  className={classes.blogTitleLink}
                >
                  <Typography variant='body2'>
                    by {relatedPosts.user.name}
                  </Typography>
                </Link>
              </Box>
              <ActionButton
                onClick={() => navigate(`${routing.blog}/${relatedPosts.slug}`)}
                className={classes.btnContinue}
              >
                Continue Reading
              </ActionButton>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default RelatedItems;
