import React, { FC } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Image } from 'src/components';
import { routing } from 'src/utils';
import moment from 'moment';
import cx from 'classnames';
import { ActionButton } from 'src/components/Forms';
import PersonIcon from '@mui/icons-material/Person';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  useMediaQuery,
} from '@mui/material';
import { useStyles } from './BlogCardStyles';

interface IBlogItem {
  blog: Record<string, any>;
}
const BlogItem: FC<IBlogItem> = ({ blog }) => {
  const isMobile = useMediaQuery('(max-width: 352px)');

  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card>
        <CardContent sx={{ padding: '0' }}>
          <Box className={classes.blogImgWrapper}>
            <Image
              src={blog.thumbnail.url}
              alt={blog.title}
              fallback={blog.thumbnail.url}
              className={classes.blogImg}
            />

            <Box className={classes.blogCategory}>
              <ActionButton
                onClick={() =>
                  navigate(`${routing.blogCategory}/${blog.category}`)
                }
                className={classes.btnCategory}
              >
                {blog.category}
              </ActionButton>
            </Box>
          </Box>
          <Box className={classes.blogContent}>
            <Link
              to={`${routing.blog}/${blog.slug}`}
              className={classes.blogTitleLink}
            >
              <Typography variant='body2' className={classes.blogTitle}>
                {blog.title.length > 90
                  ? blog.title.substring(0, 90) + '...'
                  : blog.title}
              </Typography>
            </Link>
            <Box
              className={cx(classes.blogTimeWrapper, {
                [classes.blogTimeWrapperMobile]: isMobile,
              })}
            >
              <Box className={classes.blogTime}>
                <CalendarMonthIcon />
                <Typography variant='body2'>
                  {moment(blog.createdAt).format('MMMM Do YYYY')}
                </Typography>
              </Box>
              <Box className={classes.userWrapper}>
                <PersonIcon />
                <Link
                  to={`${routing.blogUser}/${blog.user._id}`}
                  className={classes.blogTitleLink}
                >
                  <Typography variant='body2'>{blog.user.name}</Typography>
                </Link>
              </Box>
            </Box>
            <Typography variant='body2' className={classes.blogDescription}>
              {blog.description.length > 150
                ? blog.description.substring(0, 150) + '...'
                : blog.description}
            </Typography>
            <ActionButton
              onClick={() => navigate(`${routing.blog}/${blog.slug}`)}
              className={classes.btnMore}
            >
              Read More
            </ActionButton>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default BlogItem;
