import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  relatedPostWrapper: {
    marginTop: '2rem',
  },

  title: {
    fontSize: '1.7rem',
    fontWeight: 700,
    marginBottom: '1rem',
    wordBreak: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cardImg: {
    width: '100%',
    height: '300px',
    objectFit: 'cover',
    marginBottom: '.5rem',
  },
  cardContent: {
    padding: '0 0.6rem',
  },
  featureWrapper: {
    display: 'flex',
    justifyContent: ' flex-start',
    alignItems: 'center',
    gap: '1rem',
    marginBottom: '.5rem',
  },

  timePosted: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',

    '& p': {
      fontSize: '.8rem',
      fontWeight: 400,
      color: theme.palette.black[100],
      margin: '0px',
    },
  },

  category: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
    '& p': {
      fontSize: '.8rem',
      fontWeight: 700,
      color: theme.palette.text.primary,
    },
  },
  descriptions: {
    marginBottom: '.5rem',
    '& p:nth-child(1)': {
      fontSize: '1rem',
      fontWeight: 600,
      color: theme.palette.black[500],
      marginBottom: '.5rem',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      minHeight: '3rem',
      '&:hover': {
        color: theme.palette.lightBlue.dark,
      },
    },
    '& p:nth-child(2)': {
      fontSize: '.8rem',
      fontWeight: 400,
      color: theme.palette.black[100],
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: '.5rem',
  },

  btnContinue: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: 700,
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
    padding: '0px',
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },

  userWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
    '& p': {
      fontSize: '.8rem',
      fontWeight: 500,
      color: theme.palette.text.primary,
    },
  },
  blogTitleLink: {
    textDecoration: 'none',
    color: '#263238',
  },
}));
